<template>
  <div class="videoDiv">
      <div class="videoPlayer">
        <div id="J_prismPlayer"></div>
        <!-- <i class="el-icon-caret-right playBtn" v-show="showPlayBtn" @click="onClickPlayBtn"></i> -->
        <div id="ad-container"></div>
      </div>
      <div id="mainContainer">
        <div id="content">
          <video playsinline id="testVideo" width="100%" height="100%" :poster="coverImg">
            <source src="https://www.novatipsy.com/ad_movie.mp4"></source>
          </video>
        </div>
        <div id="adContainer">
        </div>
      </div>
      <!-- 需要播放激励广告蒙版 -->
      <div class="maskDiv" v-if="!videoData.playStatus" ref="mackDiv" id="mackDiv">
        <div>
          <div class="lockImgDiv">
            <img class="lockImg" src="@/assets/lock.png" alt=""/>
          </div>
          <p class="lockDsc">Need to see ads.</p>
          <div class="btnDiv">
            <!-- <div :class="unblockDisabled?'btn disabledBtn':'btn'" @click="onClickPay" :disabled="unblockDisabled">Unblock</div> -->
            <el-button class="btn" :loading="unblockDisabled" :disabled="unblockDisabled" @click="onClickPay">Unblock
            </el-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import httpTools from "@/utils/httpAPI/httpTools";
  export default {
    name: 'videoEl',
    props:{
      coverImg:{
        type:String,
        default:''
      },
      videoProps:{
        type: Object,
        default: () => {
          return {};
        }
      },
      adRecordList:{
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        unblockDisabled: false,
        thePlayletResId:'',
        videoData:{}
      }
    },
    created(){
      this.videoData = this.videoProps;
      this.thePlayletResId = this.videoData.playletResId;

    },
    mounted(){
      if (this.videoData.playStatus) {
        // initDesktopAutoplayExample((val) => {
        //   this.getVideoPlayAuth(this.thePlayletResId, false)
        // });
        this.getVideoPlayAuth(this.thePlayletResId, false)
      }
    },
    beforeDestroy() {
      if (this.player) {
        this.player.pause();
        this.player.dispose();
        this.player = null;
      }
    },
    methods:{
      /**
       * 当前视频播放授权
       * @param {*} playletResId
       * @param isSeeCom
       */
      getVideoPlayAuth(playletResId, isSeeCom) {
        httpTools.getVideoPlayAuth(playletResId).then((res) => {
          if (res.status == "SUCCESS") {
            this.videoData = { ...this.videoData, ...res.content };
            // if (isSeeCom) {
            //   this.videoData.playStatus = true;
            // }
            this.initPlayer(isSeeCom);
          } else {
            this.$message.error("Please contact the administrator!");
          }
        });
      },

      /**
       * 阿里云播放器
       * @param {*} autoplay
       */
      initPlayer(autoplay = true) {
        // 删除贴片广告元素
        document.getElementById("content")?.remove();
        document.getElementById("mackDiv")?.remove();
        // if (this.player) {
        //   this.player.dispose();
        //   this.player = null;
        // }
        if (this.videoData.playStatus==false) {
          this.$emit('unlockFc',this.videoData.playletResId);
        }
        this.player = new Aliplayer(
          {
            id: "J_prismPlayer",
            width: "100%",
            height: "300px",
            autoplay: true,
            isLive: false,
            encryptType: 1,
            cover: this.videoData.videoMeta.coverURL,
            vid: this.videoData.videoMeta.videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
            playauth: this.videoData.playAuth, // 必选参数。音视频播放凭证。
            skinLayoutIgnore: [
              // "bigPlayButton", // 隐藏大播放按钮
              "controlBar.subtitle",
              "controlBar.setting"
            ],
            clickPause: true,
            controlBarVisibility: "always",
            skinLayout: [
              { name: "bigPlayButton", align: "cc" },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  { name: "progress", align: "blabs", x: 0, y: 44 },
                  { name: "playButton", align: "tl", x: 15, y: 12 },
                  { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                  { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                  { name: "subtitle", align: "tr", x: 15, y: 12 },
                  { name: "setting", align: "tr", x: 15, y: 12 },
                  { name: "volume", align: "tr", x: 5, y: 10 }
                ]
              }
            ]
          },
          (player) => {
            console.log("The player is created----getCurrentTime:", player.getCurrentTime);
          }
        );
        this.player.on("ended", () => {
          this.$emit('playEnded');
        });
      },

      /**
       * 点击解锁
       */
      onClickPay() {
        this.$store.commit('update_cur_show_ad_playletResId','')
        this.unblockDisabled = false;
        this.$message.error("Advertisement not found, please try again!");
        // let that = this;
        // let isSeeCom = false;
        // this.unblockDisabled = true;
        // this.showRewardAD(
        //   ()=>{
        //     //--广告展示--
        //     that.$store.commit('update_cur_show_ad_playletResId',that.videoData.playletResId)
        //   },
        //   () => {
        //     isSeeCom = true;
        //   },
        //   () => {
        //     // --广告未拉取到--
        //     that.$store.commit('update_cur_show_ad_playletResId','')
        //     that.unblockDisabled = false;
        //     that.$message.error("Advertisement not found, please try again!");
        //   },
        //   () => {
        //     // --激励广告位已关闭--
        //     that.$store.commit('update_cur_show_ad_playletResId','')
        //     that.unblockDisabled = false;
        //     if (isSeeCom) {
        //       that.getVideoPlayAuth(that.thePlayletResId, isSeeCom);
        //     }
        //   }
        // );
      },
      showRewardAD(showAdFun,successFun, failFun, closeFun) {
        googletag.cmd.push(() => {
          const rewardedSlot = googletag.defineOutOfPageSlot("/22872161438,23086401644/novatipsy.com_0806_1x1", googletag.enums.OutOfPageFormat.REWARDED).addService(googletag.pubads());
          if (rewardedSlot) {
            const slotRenderEnded = (evt) => {
              if (evt.isEmpty) {
                console.log("--广告未拉取到--");
                failFun();
                googletag.pubads().removeEventListener("slotRenderEnded", slotRenderEnded);
                return;
              }
            };
            googletag.pubads().addEventListener("slotRenderEnded", slotRenderEnded);
            const rewardedSlotReady = (evt) => {
              console.log("--广告展示--");
              evt.makeRewardedVisible();
              // 在此处执行您的逻辑
              showAdFun();
              googletag.pubads().removeEventListener("rewardedSlotReady", rewardedSlotReady);
            };
            googletag.pubads().addEventListener("rewardedSlotReady", rewardedSlotReady);

            const rewardedSlotGranted = (evt) => {
              console.log("--广告已观看可以发放奖励--");
              // 在此处执行您的逻辑
              successFun();

              googletag.pubads().removeEventListener("rewardedSlotGranted", rewardedSlotGranted);
            };
            googletag.pubads().addEventListener("rewardedSlotGranted", rewardedSlotGranted);

            const rewardedSlotClosed = (evt) => {
              console.log("--激励广告位已关闭--");
              closeFun();
              googletag.destroySlots([rewardedSlot]);
              googletag.pubads().removeEventListener("rewardedSlotClosed", rewardedSlotClosed);
            };
            googletag.pubads().addEventListener("rewardedSlotClosed", rewardedSlotClosed);

            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
            googletag.display(rewardedSlot);
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>

  .videoDiv {
    position: relative;
    height: 300px;
    background: rgb(33, 34, 37);

    .videoPlayer {
      position: relative;
      overflow: hidden;

      #J_prismPlayer .aliplayer-control {
        display: block !important; /* 确保控制按钮显示 */
      }

      .playBtn {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #fff;
        color: #2c3e50;
        z-index: 99;
        border-radius: 50%;
        font-size: 40px;
        line-height: 60px;
      }
    }

    .maskDiv {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      background: rgba(33, 34, 37, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .lockImg {
        width: 32.5px;
        height: 32.5px;
      }

      .lockDsc {
        font-size: 16px;
        font-weight: 600;
      }

      .btn {
        width: 173px;
        height: 45px;
        background: #5f74ff;
        border-radius: 5px;
        // line-height: 45px;
        font-weight: bold;
        margin: auto;
        font-size: 18px;
        border: none;
        color: #fff;
      }

      .disabledBtn {
        pointer-events: none;
        cursor: default; /* 这将改变鼠标光标为默认样式，表明该元素不可点击 */
        filter: grayscale(100%);
      }
    }

    #ad-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  #mainContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    #content,
    #adContainer,
    #coverImgDiv {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    #coverImgDiv {
      background: #000;

      img {
        // width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }
</style>
<style>
.el-message{
  z-index: 999999;
}
</style>
