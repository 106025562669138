<template>
  <div class="videoDetailsDiv" v-loading="!videoDetailsData">
    <div class="topDiv">
      <img class="returnImg" @click="onClickReturn" src="@/assets/return.png" alt="" />
      <span class="title_p">{{ videoDetailsData.name }}</span>
      <div></div>
    </div>
    <div class="videoContentDiv">
      <videoElVue v-if="videoData.playletResId" :key="videoData.playletResId" :coverImg="videoDetailsData.coverImg" :adRecordList="adRecordObj[playletId]" :videoProps="videoData" @unlockFc="onUnlockFc" @playEnded="onPlayEnded"></videoElVue>
    </div>
    <!-- 视频下方 内容 -->
    <div class="contentDiv">
      <p class="desc_p" v-if="videoDetailsData.description">{{ videoDetailsData.description }}</p>
      <p class="title_p mg_top_24">
        Selection<span class="desc_p2">{{ videoDetailsData.videoCount }} episodes in total</span>
      </p>
      <p class="desc_p3" v-if="videoData.fileName">{{ videoData.fileName }}</p>
      <!-- 集数 -->
      <div class="playList">
        <div
          class="playItem"
          :class="item.playletResId == videoData.playletResId ? 'playItem_active' : ''"
          :id="'playItem_' + item.playletResId"
          :ref="'playItem_' + item.playletResId"
          v-for="(item, index) in videoDetailsData.playletResList"
          :key="index"
          @click="onClickPlay(item, index)"
        >
          <div>{{ index + 1 }}</div>
          <img class="payImg" v-if="!item.playStatus" src="@/assets/paylock.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 广告 -->
    <p style="font-size: 12px; margin-bottom: 0px">Advertisement</p>
<!--    <div id="div-gpt-ad-1723001986561-0" style="min-width: 300px; min-height: 250px"></div>-->
    <div id="div-gpt-ad-1723001986561-0" style="min-width: 300px;"></div>
  </div>
</template>
<script>
import httpTools from "@/utils/httpAPI/httpTools";
import videoElVue from "./videoEl.vue";
export default {
  components: {
    videoElVue
  },
  data() {
    return {
      videoDetailsData: "",
      videoSrc: "",
      videoData: {},
      playletId: "",
      player: "",
      currentVideoIndex: 0,
      unblockDisabled: false,
      unblockTimeOut: "",
      showADing: false,
      thePlayletResId: 0,

      adsManager: "",
      adsLoader: "",
      adDisplayContainer: "",
      playButton: "",
      videoContent: "",
      adsInitialized: "",
      autoplayAllowed: "",
      autoplayRequiresMuted: "",
      showCoverImg: true,
      adRecordObj: {},
      isReresh: true
      // showSliceAD:true
    };
  },
  computed: {
    showPlayBtn() {
      if (this.player && this.player.getStatus() != "playing") {
        return true;
      } else {
        return false;
      }
    }
    // showLock() {
    //   return (item) => {
    //     return !item.playStatus && !(this.adRecordObj[this.playletId] && this.adRecordObj[this.playletId].indexOf(item.playletResId) != -1);
    //   };
    // }
  },
  created() {
    this.playletId = this.$route.params.playletId;
    console.log("this.$route.params:", this.$route.params);
    if (localStorage.getItem("kiwitok_seeAD_record")) {
      let val = localStorage.getItem("kiwitok_seeAD_record");
      this.adRecordObj = JSON.parse(val);
    }
    let playletResId = this.$route.params.playletResId;
    if (this.$store.state.curShowAdPlayLetResId) {
      playletResId = this.$store.state.curShowAdPlayLetResId;
    }
    this.getVideoDetailByWeb(this.playletId, playletResId);
  },
  mounted() {
    console.log("video-details----mounted:");
    // this.refreshAD();
  },
  beforeDestroy() {
    console.log("videoDetails--------beforeDestroy");
    if (this.player) {
      this.player.pause();
      this.player.dispose();
      this.player = null;
    }
  },
  methods: {
    refreshAD() {
      googletag.pubads().refresh();
    },
    /**
     * 获取视频集数列表
     * @param {*} playletId
     * @param {*} playletResId
     */
    getVideoDetailByWeb(playletId, playletResId) {
      httpTools.getVideoDetailByWeb(playletId).then((res) => {
        if (res.status == "SUCCESS") {
          if (this.adRecordObj[playletId] && this.adRecordObj[playletId].length > 0) {
            res.content.playletResList.forEach((el) => {
              if (el.playStatus == false && this.adRecordObj[playletId].indexOf(el.playletResId) != -1) {
                el.playStatus = true;
              }
            });
          }
          this.videoDetailsData = res.content;
          if (playletResId == "0") {
            this.thePlayletResId = this.videoDetailsData.playletResList[0].playletResId;
            this.currentVideoIndex = 0;
          } else {
            this.thePlayletResId = playletResId;
            this.currentVideoIndex = this.videoDetailsData.playletResList.findIndex((item) => item.playletResId == playletResId);
          }
          this.onPlayVideo();
        } else {
          this.$message.error("Please contact the administrator!");
        }
      });
    },
    onPlayVideo() {
      this.videoData = this.videoDetailsData.playletResList[this.currentVideoIndex];
      // 控制当前集数显示在屏幕上
      this.$nextTick(() => {
        let el = document.getElementById("playItem_" + this.thePlayletResId);
        if (el) {
          if (el.scrollIntoViewIfNeeded) {
            el.scrollIntoViewIfNeeded();
          } else {
            el.scrollIntoView();
          }
        }
      });
    },
    /**
     * 当前集解锁记录
     */
    onUnlockFc(playletResId) {
      if (!this.adRecordObj[this.playletId]) {
        this.adRecordObj[this.playletId] = [];
      }
      this.adRecordObj[this.playletId].push(playletResId);
      localStorage.setItem("kiwitok_seeAD_record", JSON.stringify(this.adRecordObj));
      let item = this.videoDetailsData.playletResList.find((val) => val.playletResId == playletResId);
      if (item != null) {
        item.playStatus = true;
      }
      this.$forceUpdate();
      this.$nextTick(() => {
        let el = document.getElementById("playItem_" + this.playletId);
        if (el && el.getElementsByClassName("payImg")) {
          let imgEl = el.getElementsByClassName("payImg");
          el.removeChild(imgEl);
          this.$forceUpdate();
        }
      });
    },
    /**
     * 当前集播放完成，自动播放下一集
     */
    onPlayEnded() {
      if (this.currentVideoIndex < this.videoDetailsData.playletResList.length - 1) {
        this.currentVideoIndex++;
        let item = this.videoDetailsData.playletResList[this.currentVideoIndex];
        this.onClickPlay(item, this.currentVideoIndex);
      }
    },
    /**
     * 点击集数，播放
     * @param {} item
     */
    onClickPlay(item, index) {
      this.thePlayletResId = item.playletResId;
      this.currentVideoIndex = index;
      this.onPlayVideo();
      // this.$router.push(`/videoDetails/${this.playletId}/${item.playletResId}`);
    },
    /**
     * 返回首页
     */
    onClickReturn() {
      this.$router.replace("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.videoDetailsDiv {
  .topDiv {
    padding: 10px 14px;
    // text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .returnImg {
      width: 16px;
      height: 16px;
      display: block;
    }

    p {
      margin: 0px;
    }

    .title_p {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .videoContentDiv {
    height: 300px;
    background: rgb(33, 34, 37);
  }
  .contentDiv {
    padding: 16px;
    text-align: left;

    p {
      margin: 0px;
    }

    .title_p {
      font-size: 18px;
      font-weight: bold;
    }

    .mg_top_24 {
    }

    .desc_p {
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .desc_p2 {
      margin-left: 5px;
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
    }

    .desc_p3 {
      margin-top: 8px;
      margin-bottom: 10px;
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
    }

    .playList {
      display: flex;
      overflow-x: auto;
      // flex-wrap: wrap;
      padding-bottom: 10px;

      .playItem {
        margin-right: calc((100% - 288px) / 5);
        // margin-top: 10px;
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        line-height: 48px;
        background-color: #f2f4f7;
        color: #212225;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;

        .payImg {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 22.5px;
        }

        // .lockDiv {
        //   position: absolute;
        //   left: 0px;
        //   top: 0px;
        //   width: 100%;
        //   height: 100%;
        //   background: rgba(33, 34, 37, 0.5);

        //   .lockImg {
        //     width: 18px;
        //   }
        // }
      }

      .playItem_active {
        color: #5f74ff;
        background-color: #eff1ff;
      }

      // .playItem:nth-child(6n) {
      //   margin-right: 0px;
      // }
      .playItem:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
